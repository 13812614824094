import React from 'react'

import Layout from '../components/layout'
import { Carousel } from 'react-bootstrap'

const IndexPage = () => (
  <Layout>

    <div className='fh5co-hero carousel slide' data-ride='carousel'>
        <Carousel controls={false} interval={7000} pauseOnHover={false}>
            <Carousel.Item>
                <div className='desc'>
                        <h2>
                            <b>SIGRId - IAS</b>
                        </h2>
                        <div>
                            <h3 className='main-description'>SIstema Gestione Rischi Interno digitalizzato</h3>
                        </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='desc'>
                        <h2>
                            <b style={{ fontSize:'50px' }}>Quando il mondo accademico si integra con quello del lavoro, nascono sinergie di sviluppo</b>
                        </h2>
                        {/* <div>
                            <h3 className='main-description'>testo aggiuntivo</h3>
                        </div> */}
                </div>
            </Carousel.Item>
        </Carousel>
    </div>

    {/* <div className='fh5co-hero' style={{ height: '100vh' }}>
        <div className='desc'>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2>
                            <b>SIGRId</b>
                        </h2>
                        <div>
                            <h3 className='main-description'>SIstema Gestione Rischi Interno digitalizzato</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div> */}

  </Layout>

)

export default IndexPage
